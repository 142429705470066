import {createSlice} from '@reduxjs/toolkit';
import {get} from 'core/configureRequest';

const initialState = {
    subscriptions: [],
};

const promotedOutpatientSlice = createSlice({
    name: 'promotedOutpatient',
    initialState,
    reducers: {
        receivePromotedOutpatientSubscriptions(state, action) {
            return {
                ...state,
                subscriptions: action.payload,
            };
        },
    },
});

export const {receivePromotedOutpatientSubscriptions} = promotedOutpatientSlice.actions;

export const getPromotedOutpatientListingSubscriptions = (accountId) => (dispatch, getState) => {
    return get(`/proxy/api/promoted-op/subscription/?account_id=${accountId}&is_active=true`)
        .then((data) => {
            dispatch(receivePromotedOutpatientSubscriptions(data.data.subscriptions));
        })
        .catch((e) => {
            return Promise.reject(e);
        });
};

export default promotedOutpatientSlice.reducer;
