import {createSlice} from '@reduxjs/toolkit';
import {omit} from 'utils/helpers';

const initialState = {
    successNotification: '',
    errorNotification: '',
    showUpdateNotification: false,
    messages: {},
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        notifySuccess(state, action) {
            return {
                ...state,
                errorNotification: '',
                successNotification: action.payload,
                showUpdateNotification: false,
            };
        },
        notifyError(state, action) {
            return {
                ...state,
                errorNotification: action.payload,
                successNotification: '',
                showUpdateNotification: false,
            };
        },
        hideNotification(state) {
            return {
                ...state,
                errorNotification: '',
                successNotification: '',
                showUpdateNotification: false,
            };
        },
        showMessage(state, action) {
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [action.payload.id]: {
                        message: action.payload.message,
                        messageClass: action.payload.messageClass,
                    },
                },
            };
        },
        hideMessage(state, action) {
            if (!action.payload.id) {
                return state;
            }

            return {
                ...state,
                messages:
                    action.payload.id && state.messages
                        ? {...omit(state.messages, action.payload.id)}
                        : {},
            };
        },
    },
});

export const {notifyError, notifySuccess, hideNotification, showMessage, hideMessage} =
    notificationSlice.actions;

export const notifySuccessWithTimeout = (message, timeoutMs= 3000) => (dispatch, getState) => {
    dispatch(notifySuccess(message));

    setTimeout(() => dispatch(hideNotification()), timeoutMs);
};

export const notifyErrorWithTimeout = (message, timeoutMs= 3000) => (dispatch, getState) => {
    dispatch(notifyError(message));

    setTimeout(() => dispatch(hideNotification()), timeoutMs);
};

export const updateMessage = (id, messageClass, message) => (dispatch, getState) => {
    dispatch(showMessage({id, messageClass, message}));

    if (messageClass !== 'error') {
        setTimeout(() => dispatch(hideMessage(id)), 3000);
    }
};

export default notificationSlice.reducer;
