import {createSlice} from '@reduxjs/toolkit';
import {get} from 'core/configureRequest';

const initialState = {
    subscriptions: [],
};

const promotedSlice = createSlice({
    name: 'promoted',
    initialState,
    reducers: {
        receivePromotedSubscriptions(state, action) {
            return {
                ...state,
                subscriptions: action.payload,
            };
        },
    },
});

export const {receivePromotedSubscriptions} = promotedSlice.actions;

export const getPromotedListingSubscriptions = (accountId) => (dispatch, getState) => {
    return get(`/proxy/api/promoted/subscription/?account_id=${accountId}&is_active=true`)
        .then((data) => {
            dispatch(receivePromotedSubscriptions(data.data.subscriptions));
        })
        .catch((e) => {
            return Promise.reject(e);
        });
};

export default promotedSlice.reducer;
