import {createSlice} from '@reduxjs/toolkit';
import {get} from 'core/configureRequest';

const initialState = {
    subscriptions: [],
};

const vipOutpatientFlatSlice = createSlice({
    name: 'vipOutpatientFlat',
    initialState,
    reducers: {
        receiveVipOutpatientFlatSubscriptions(state, action) {
            return {subscriptions: action.payload};
        },
    },
});

export const {receiveVipOutpatientFlatSubscriptions} = vipOutpatientFlatSlice.actions;

export const getVipOutpatientFlatListingSubscriptions = (accountId) => (dispatch, getState) => {
    return get(`/proxy/api/vip-op/subscription/?account_id=${accountId}&is_active=true`)
        .then((data) => {
            dispatch(receiveVipOutpatientFlatSubscriptions(data.data.subscriptions));
        })
        .catch((e) => {
            return Promise.reject(e);
        });
};

export default vipOutpatientFlatSlice.reducer;
