import {createSlice} from '@reduxjs/toolkit';
import {get} from 'core/configureRequest';

const initialState = {
    subscriptions: [],
};

const standardOutpatientSlice = createSlice({
    name: 'standardOutpatient',
    initialState,
    reducers: {
        receiveStandardOutpatientSubscriptions(state, action) {
            return {
                ...state,
                subscriptions: action.payload,
            };
        },
    },
});

export const {receiveStandardOutpatientSubscriptions} = standardOutpatientSlice.actions;

export const getStandardOutpatientListingSubscriptions = (accountId) => (dispatch, getState) => {
    return get(`/proxy/api/standard-op/subscription/?account_id=${accountId}&is_active=true`)
        .then((data) => {
            dispatch(receiveStandardOutpatientSubscriptions(data.data.subscriptions));
        })
        .catch((e) => {
            return Promise.reject(e);
        });
};

export default standardOutpatientSlice.reducer;
